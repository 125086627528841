import {
  Place,
} from '@/types/place'

export const newPlace = (): Place => ({
  color: `#9E9E9EFF`,
  icon: ``,
  name: ``,
  translation: ``,
  chapterIds: [
  ],
  x: 0,
  y: 0,
})
