
































import Vue from 'vue'
import PlaceButton from '@/components/Place/Button.vue'
import {
  placeStore,
  snackbarStore,
} from '@/store'
import {
  Place,
} from '@/types/place'
import {
  Id,
} from '@/types/base'
import {
  DataTableHeader,
} from 'vuetify'

export default Vue.extend({
  components: {
    PlaceButton,
  },
  data: () => ({
    headers: [
      {
        text: `ID`,
        value: `id`,
        width: 1,
      },
      {
        text: `Name`,
        value: `name`,
        class: `text-no-wrap`,
      },
      {
        text: `Translation`,
        value: `translation`,
      },
      {
        text: `Icon / Color`,
        value: `icon`,
        sortable: false,
      },
      {
        text: `Coordinates`,
      },
      {
        text: `Chapter IDs`,
        value: `chapterIds`,
      },
    ] as DataTableHeader[],
  }),
  computed: {
    places (): (Place & Id)[] {
      return placeStore.data
    },
  },
  async created () {
    try {
      await placeStore.getAll()
    } catch (err) {
      snackbarStore.addSiteError({
        err,
        snackMessage: `Unable to load places`,
      })
    }
  },
})
